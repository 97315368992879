import React from "react";
import styles from "./TextInput.module.css"
import InputStyle from '../../styles/Input.module.css'
import ToolTip from '../Tooltip/ToolTip'

const TextInput = ({ label, value, onChange, onBlur, name, disabled, error, className, type, placeholder }) => (
  <ToolTip className={`${styles.TextInput} ${className ? className : ''}`} error={error}>
    {label && <div className={styles.Label}>{label}</div>}
    <input
      className={`${styles.TextInput} ${InputStyle.Input} ${error ? InputStyle.Error : ''}`}
      type={type ? type : 'text'}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  </ToolTip>
)
export default TextInput