import React from "react";
import styles from "./CreatePromotionForm.module.css";
import DateInput from "../DateInput/DateInput";
import TimeInput from "../TimeInput/TimeInput";
import TextInput from "../TextInput/TextInput";
import SelectInput from "../SelectInput/SelectInput";
import Button from "../Button/Button";
import CloseIcon from "../../icons/Close.svg";
import AlertMessage from "../AlertMessage/AlertMessage";

export default class CreatePromotionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Promotion: {
        StartDate: new Date(
          new Date(
            new Date().setDate(new Date().getDate() + 1)
          ).toLocaleDateString()
        ).toISOString(),
        EndDate: new Date(
          new Date(
            new Date().setDate(new Date().getDate() + 1)
          ).toLocaleDateString()
        ).toISOString(),
        StartTime: new Date().toLocaleTimeString(),
        EndTime: new Date().toLocaleTimeString(),
        PromotionalMessage: "",
        Location: "",
        Active: false
      },
      ERROR: {},
      InProgress: false,
      alert: false
    };
  }

  handleChange = e => {
    const { Promotion, ERROR } = this.state;
    this.setState({
      Promotion: {
        ...Promotion,
        [e.target.name]: e.target.value,
        ERROR: { ERROR, [e.target.name]: null }
      }
    });
  };

  handlaSubmit = () => {
    this.setState({ InProgress: true, alert: false }, () => {
      let error = false;
      let ERROR = {};
      const { Promotion } = this.state;
      const { EndDate, StartDate } = Promotion;
      Object.keys(Promotion).forEach(field => {
        if (!Promotion[field].toString()) {
          error = true;
          ERROR[field] = `${field} is Required.`;
        }

        if (EndDate && EndDate < StartDate) {
          error = true;
          ERROR.EndDate = `End Date can't be less than Start Date.`;
        }
        this.setState({ ERROR });
      });

      if (!error) {
        this.props.onSubmit(Promotion, success => {
          if (!success) this.setState({ InProgress: false, alert: true });
        });
        return;
      }
      this.setState({ InProgress: false });
    });
  };

  render() {
    const { ERROR, InProgress, alert } = this.state;
    const {
      StartDate,
      EndDate,
      StartTime,
      EndTime,
      Active,
      PromotionalMessage,
      Location
    } = this.state.Promotion;
    return (
      <div className={styles.Modal}>
        <div className={styles.AddPromotionForm}>
          {alert && (
            <AlertMessage handleClose={() => this.setState({ alert: false })}>
              Oops!! something went wrong. Please try again.
            </AlertMessage>
          )}
          <button className={styles.Close} onClick={this.props.handleClose}>
            <img src={CloseIcon} alt="" />
          </button>
          <DateInput
            className={`${styles.Input} ${styles.StartDate}`}
            name="StartDate"
            label="Start Date:"
            value={StartDate}
            onChange={this.handleChange}
            error={ERROR && ERROR.StartDate}
          />
          <DateInput
            className={`${styles.Input} ${styles.EndDate}`}
            name="EndDate"
            label="End Date:"
            value={EndDate}
            onChange={this.handleChange}
            error={ERROR && ERROR.EndDate}
          />
          <TimeInput
            className={`${styles.Input} ${styles.StartTime}`}
            name="StartTime"
            label="Start Time:"
            value={StartTime}
            onChange={this.handleChange}
            error={ERROR && ERROR.StartTime}
          />
          <TimeInput
            className={`${styles.Input} ${styles.EndTime}`}
            name="EndTime"
            label="End Time:"
            value={EndTime}
            onChange={this.handleChange}
            error={ERROR && ERROR.EndTime}
          />
          <SelectInput
            className={`${styles.Input} ${styles.Active}`}
            name="Active"
            label="Active:"
            value={Active}
            onChange={this.handleChange}
            error={ERROR && ERROR.Active}
            disabled
          />
          <TextInput
            className={`${styles.Input} ${styles.PromotionalMessage}`}
            name="PromotionalMessage"
            label="Promotional Message:"
            value={PromotionalMessage}
            onChange={this.handleChange}
            error={ERROR && ERROR.PromotionalMessage}
          />
          <TextInput
            className={`${styles.Input} ${styles.Location}`}
            name="Location"
            label="Location:"
            value={Location}
            onChange={this.handleChange}
            error={ERROR && ERROR.Location}
          />
          <Button
            className={`${styles.Create}`}
            type="Update"
            progress={InProgress}
            onClick={this.handlaSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    );
  }
}
