import React from "react";
import styles from "./TimeInput.module.css"
import InputStyle from '../../styles/Input.module.css'
import ToolTip from '../Tooltip/ToolTip'
import '../../styles/CustomReactDatepicker.css'
import DatePickerInput from 'react-datepicker';

const TimeInput = ({ label, value, onChange, onBlur, minTime, name, disabled, error, className }) => (
  <ToolTip className={`${styles.TimeInput} ${className ? className : ''}`} error={error}>
    {label && <div className={styles.Label}>{label}</div>}
    <DatePickerInput
      className={`${styles.TimeInput} ${InputStyle.Input} ${error ? InputStyle.Error : ''}`}
      name={name}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat="hh:mm aa"
      selected={new Date('1990-05-11 ' + value)}
      onChange={(date) => date && onChange({ target: { name, value: date.toLocaleTimeString() } })}
      onBlur={onBlur}
      disabled={disabled}
    />
  </ToolTip>
)
export default TimeInput