import React from "react";
import styles from "./DateInput.module.css"
import InputStyle from '../../styles/Input.module.css'
import ToolTip from '../Tooltip/ToolTip'
import '../../styles/CustomReactDatepicker.css'
import DatePickerInput from 'react-datepicker'

const DateInput = ({ label, value, onChange, onBlur, name, disabled, error, className }) => (
  <ToolTip className={`${styles.DateInput} ${className ? className : ''}`} error={error}>
    {label && <div className={styles.Label}>{label}</div>}
    <DatePickerInput
      className={`${styles.DateInput} ${InputStyle.Input} ${error ? InputStyle.Error : ''}`}
      name={name}
      minDate={new Date().setDate(new Date().getDate() + 1)}
      selected={new Date(value)}
      dateFormat="MM/dd/yyyy"
      onChange={(date) => date && onChange({ target: { name, value: new Date(date.toLocaleDateString()).toISOString() } })}
      onBlur={onBlur}
      disabled={disabled}
    />
  </ToolTip>
)


export default DateInput