import React from "react";
import styles from "./ToolTip.module.css"
import ErrorIcon from '../../icons/Error.svg'

const ToolTip = ({ error, children, className }) => (
    <div className={`${styles.Wrapper} ${className ? className : ''}`}>
        {children}
        {error && <div className={styles.ToolTip}><span>{error}</span><img src={ErrorIcon} alt="" /></div>}
    </div>
)
export default ToolTip