import React from 'react';
import Styles from './DialogBox.module.css'
import Button from '../Button/Button'
import InfoIcon from '../../icons/Info.svg'
import ErrorIcon from '../../icons/Error.svg'
import WarningIcon from '../../icons/Warning.svg'

const DialogBox = ({ title, message, type, Ok, Cancel }) => {
    const Icons = { Error: ErrorIcon, Info: InfoIcon, Warning: WarningIcon }
    return (
        <div className={Styles.Modal}>
            <div className={Styles.DialogBox}>
                <img className={Styles.Icon} src={Icons[type]} alt='' />
                <p className={Styles.Title}>{title}</p>
                <p className={Styles.Message}>{message}</p>
                {Ok && <Button className={Styles.OkButton} type="Update" onClick={Ok}>Okay</Button>}
                {Cancel && <Button className={Styles.CancelButton} type="Update" onClick={Cancel}>Cancel</Button>}
            </div>
        </div>
    )
}

export default DialogBox