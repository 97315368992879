import React, { useState } from "react";
import styles from "./ContentManagement.module.css";
import ActiveEvents from '../ActiveEvents/ActiveEvents'
import Promotions from '../Promotions/Promotions'
import PastEvents from '../PastEvents/PastEvents'
import DialogBox from "../DialogBox/DialogBox";

const ContentManagement = () => {
    const [tab, setTab] = useState('ActiveEvents')
    const [hasChanges, setHasChanges] = useState(false)
    const [dialogBox, setDialogBox] = useState({ title: '', message: '', tab })

    const handleDialogBoxCancel = () => { setDialogBox({ title: '', message: '' }) }

    const handleTabs = (e) => {
        if (hasChanges) return setDialogBox({
            title: 'Warning!',
            message: 'Unsaved changes will be lost. Click OKAY to proceed to the next page, or click CANCEL to continue working on the same page.',
            tab: e.target.name
        })
        setTab(e.target.name)
    }

    const handleDialogBoxOk = () => {
        setTab(dialogBox.tab)
        setDialogBox({ title: '', message: '', tab })
        setHasChanges(false)
    }

    const { message, title } = dialogBox
    return (
        <div className={styles.ContentManagement} >
            {message && <DialogBox type='Warning' Cancel={handleDialogBoxCancel} Ok={handleDialogBoxOk} title={title} message={message} />}
            <div className={styles.Tab}>
                <button
                    className={`${styles.TabButtons} ${tab === 'ActiveEvents' && styles.ActiveButton}`}
                    name='ActiveEvents'
                    onClick={handleTabs}
                    disabled={tab === 'ActiveEvents'}>
                    Active Events
                </button>
                <button
                    className={`${styles.TabButtons} ${tab === 'Promotions' && styles.ActiveButton}`}
                    name='Promotions'
                    onClick={handleTabs}
                    disabled={tab === 'Promotions'}>
                    Promotions
                </button>
                <button
                    className={`${styles.TabButtons} ${tab === 'PastEvents' && styles.ActiveButton}`}
                    name='PastEvents'
                    onClick={handleTabs}
                    disabled={tab === 'PastEvents'}>
                    Past Events
                </button>
            </div>
            <div className={styles.Body}>
                {tab === 'ActiveEvents' && <ActiveEvents hasChanges={(que) => setHasChanges(que)} />}
                {tab === 'Promotions' && <Promotions hasChanges={(que) => setHasChanges(que)} />}
                {tab === 'PastEvents' && <PastEvents hasChanges={(que) => setHasChanges(que)} />}
            </div>
        </div >
    )
}
export default ContentManagement