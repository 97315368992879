import React from 'react';
import Styles from './Loading.module.css';
import LoadingIcon from '../../icons/Loading.svg'

const Loading = (props) => {
    return <div className={`${Styles.Loading} ${props.className}`}>
        <img className={Styles.LoadingIcon} src={LoadingIcon} alt='' />
    </div>
}

export default Loading