import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import ContentManagement from "../ContentManagement/ContentManagement";
import Header from "../Header/Header";
import {
  withAuthenticator,
  SignIn,
  ForgotPassword,
  RequireNewPassword
} from "aws-amplify-react";
import Amplify from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:43ebefaf-dd80-4c29-b28c-3cb9113d96d4",
    region: "us-east-1",
    userPoolId: "us-east-1_Gxqb4axlg",
    userPoolWebClientId: "1k5lgq931j7vqbc4je3shvbsrg"
  },
  API: {
    endpoints: [
      {
        name: "contentManagementAPI",
        endpoint: "https://api.smarthenterprises.com"
      }
    ]
  }
});

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path="/" exact component={ContentManagement} />
      </Switch>
    </BrowserRouter>
  </div>
);

export default withAuthenticator(App, {
  authenticatorComponents: [
    <Header />,
    <SignIn />,
    <ForgotPassword />,
    <RequireNewPassword />
  ],
  theme: {
    button: {
      backgroundColor: "#21303c",
      borderColor: "red",
      borderRadius: "5px",
      border: "none",
      fontWeight: 500,
      fontSize: "15px"
    },
    formContainer: { margin: "0px", gridRow: "1/2", gridColumn: 1 },
    container: {
      width: "100vw",
      height: "100vh",
      display: "grid",
      justifyTtems: "center",
      alignItems: "center"
    },
    a: { color: "#21303c" }
  }
});
