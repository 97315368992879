import React from "react";
import styles from "./ActiveEvents.module.css"
import Table from "../Table/Table"
import CreateOrUpdateEventForm from '../CreateOrUpdateEventForm/CreateOrUpdateEventForm'
import DialogBox from "../DialogBox/DialogBox"
import { API } from 'aws-amplify'

export default class ActiveEvents extends React.Component {
    constructor(props) {
        super(props)
        this.state = { events: {}, isLoading: true, title: '', message: '' }
    }

    componentDidMount() {
        API.get('contentManagementAPI', '/events?active=true')
            .then((data) => {
                this.setState({ events: data }, () => {
                    this.setState({ isLoading: false })
                })
            }).catch((err) => {
                this.setState({ isLoading: false, title: 'Oops!!', message: 'Sorry an error has occurred. Please reload the page again.' })
            })
    }

    handleCreateEvent = (event, CallBack) => {
        API.post('contentManagementAPI', '/events', { body: event })
            .then((data) => CallBack(true, data))
            .catch((err) => {
                console.log(err)
                CallBack(false, err)
            })
    }

    handleUpdateEvents = (updateEvents, CallBack) => {
        API.put('contentManagementAPI', '/events', { body: Object.values(updateEvents) })
            .then(() => CallBack(true)).catch((err) => {
                this.setState({ title: 'Oops!!', message: 'An error has occurred. Please try again.' })
                CallBack(false, err)
            })
    }

    handleDeleteEvents = (deleteEvents, CallBack) => {
        API.del('contentManagementAPI', '/events', { body: Object.values(deleteEvents) })
            .then(() => CallBack(true, deleteEvents)).catch((err) => {
                this.setState({ title: 'Oops!!', message: 'An error has occurred. Please try again.' })
                CallBack(false, err)
            })
    }

    //validating
    handleEventValidation = (events) => {
        let errors = false
        Object.values(events).forEach((item) => {
            let ERROR = {}
            Object.keys(item).forEach((field) => {
                if (!item[field].toString()) {
                    errors = true
                    ERROR[field] = `${field} is Required.`
                }
                if (field === 'EndTime' && new Date(`1990-05-11 ${item.EndTime}`) <= new Date(`1990-05-11 ${item.StartTime}`)) {
                    errors = true
                    ERROR[field] = "End time can't be less than or equal start time."
                }
            })
            events[item.Id].ERROR = ERROR
        })
        return { errors, Data: events }
    }

    render() {
        const { events, isLoading, title, message } = this.state
        return (
            <div className={styles.ActiveEvents}>
                {message && <DialogBox type='Error' Ok={() => this.setState({ title: '', message: '' })} title={title} message={message} />}
                <Table
                    Title="Upcoming Events"
                    isLoading={isLoading}
                    hasChanges={this.props.hasChanges}
                    AddItemForm={CreateOrUpdateEventForm}
                    Create={this.handleCreateEvent}
                    Update={this.handleUpdateEvents}
                    Delete={this.handleDeleteEvents}
                    Validate={this.handleEventValidation}
                    Data={Object.keys(events).length > 0 && events}
                />
            </div>
        )
    }
}
