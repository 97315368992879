import React from "react";
import styles from "./Button.module.css"

const Button = ({ onClick, type, SVG, disabled, children, className, progress }) => (
    <button
        className={`${styles.Button} ${styles[type]} ${className} ${progress && styles.InProgress}`}
        onClick={onClick}
        disabled={disabled}>
        {SVG ? <img src={SVG} alt='' /> : null}
        {children ? children : type}
    </button>
)
export default Button