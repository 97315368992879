import React from "react";
import styles from "./Promotions.module.css";
import Table from "../Table/Table";
import AddPromotionForm from "../CreatePromotionForm/CreatePromotionForm";
import DialogBox from "../DialogBox/DialogBox";
import { API } from "aws-amplify";

export default class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { promotions: {}, isLoading: true, title: "", message: "" };
  }

  componentDidMount() {
    API.get("contentManagementAPI", "/promotions")
      .then(data => {
        this.setState({ promotions: data }, () => {
          this.setState({ isLoading: false });
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          title: "Oops!!",
          message: "Sorry an error has occurred. Please reload the page again."
        });
      });
  }

  handleCreatePromotion = (promotion, CallBack) => {
    API.post("contentManagementAPI", "/promotions", { body: promotion })
      .then(data => CallBack(true, data))
      .catch(err => CallBack(false, err));
  };

  handleUpdatePromotions = (updatePromotions, CallBack) => {
    API.put("contentManagementAPI", "/promotions", {
      body: Object.values(updatePromotions)
    })
      .then(() => CallBack(true))
      .catch(err => {
        this.setState({
          title: "Oops!!",
          message: "An error has occurred. Please try again."
        });
        CallBack(false, err);
      });
  };

  handleDeletePromotions = (deletePromotions, CallBack) => {
    API.del("contentManagementAPI", "/promotions", {
      body: Object.values(deletePromotions)
    })
      .then(() => CallBack(true, deletePromotions))
      .catch(err => {
        this.setState({
          title: "Oops!!",
          message: "An error has occurred. Please try again."
        });
        CallBack(false, err);
      });
  };

  //validating
  handlePromotionsValidation = (promotions, Items) => {
    let errors = false;
    Object.values(promotions).forEach(item => {
      let ERROR = {};
      Object.keys(item).forEach(field => {
        if (!item[field].toString()) {
          errors = true;
          ERROR[field] = `${field} is Required.`;
        }

        if (
          field === "EndDate" &&
          new Date(item.EndDate) < new Date(item.StartDate)
        ) {
          errors = true;
          ERROR[field] = "End Date can't be less than Start Date.";
        }
      });
      promotions[item.Id].ERROR = ERROR;
    });

    const activePromotions = Object.values(Items).filter(
      Item => Item.Active === true
    );
    if (activePromotions.length > 1) {
      errors = true;
      activePromotions.forEach(activePromotion => {
        if (!promotions[activePromotion.Id]) {
          activePromotion.ERROR = { Active: "Only one can be TRUE." };
          promotions[activePromotion.Id] = activePromotion;
        } else {
          promotions[activePromotion.Id].ERROR.Active = "Only one can be TRUE.";
        }
      });
    }
    return { errors, Data: promotions };
  };

  render() {
    const { promotions, isLoading, title, message } = this.state;
    return (
      <div className={styles.Promotions}>
        {message && (
          <DialogBox
            type="Error"
            Ok={() => this.setState({ title: "", message: "" })}
            title={title}
            message={message}
          />
        )}
        <Table
          Title="Promotions"
          isLoading={isLoading}
          hasChanges={this.props.hasChanges}
          AddItemForm={AddPromotionForm}
          Create={this.handleCreatePromotion}
          Update={this.handleUpdatePromotions}
          Delete={this.handleDeletePromotions}
          Validate={this.handlePromotionsValidation}
          Data={Object.keys(promotions).length > 0 && promotions}
        />
      </div>
    );
  }
}
