import React from "react";
import styles from "./CreateOrUpdateEventForm.module.css"
import DateInput from "../DateInput/DateInput"
import TimeInput from "../TimeInput/TimeInput"
import TextInput from "../TextInput/TextInput"
import Button from '../Button/Button'
import CloseIcon from '../../icons/Close.svg'
import AlertMessage from "../AlertMessage/AlertMessage";

export default class CreateOrUpdateEventForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Event: {
                StartDate: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString()).toISOString(),
                StartTime: new Date().toLocaleTimeString(),
                EndTime: new Date().toLocaleTimeString(),
                Location: '',
                CourseName: '',
            },
            ERROR: {},
            InProgress: false,
            alert: false
        }
    }

    componentDidMount() {
        if (this.props.Event) {
            this.setState({
                Event: { ...this.props.Event, StartDate: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString()).toISOString() }
            })
        }
    }

    handleChange = (e) => {
        const { Event, ERROR } = this.state
        this.setState({ Event: { ...Event, [e.target.name]: e.target.value }, ERROR: { ERROR, [e.target.name]: null } })
    }

    handleSubmit = () => {
        this.setState({ InProgress: true, alert: false }, () => {
            let error = false
            let ERROR = {}
            const { Event } = this.state
            Object.keys(Event).forEach((field) => {
                if (!Event[field].toString()) {
                    error = true
                    ERROR[field] = `${field} is Required.`
                }
                if (field === 'EndTime' && new Date(`1990-05-11 ${Event.EndTime}`) <= new Date(`1990-05-11 ${Event.StartTime}`)) {
                    error = true
                    ERROR[field] = "End time can't be less than or equal start time."
                }
                this.setState({ ERROR })
            })

            if (!error) {
                this.props.onSubmit(Event, (success) => {
                    if (!success) this.setState({ InProgress: false, alert: true })
                })
                return
            }
            this.setState({ InProgress: false })
        })
    }

    render() {
        const { ERROR, InProgress, alert } = this.state
        const { StartDate, StartTime, EndTime, Location, CourseName } = this.state.Event
        return (
            <div className={styles.Modal}>
                <div className={styles.CreateOrUpdateEventForm}>
                    {alert && <AlertMessage handleClose={() => this.setState({ alert: false })}>Oops!! something went wrong. Please try again.</AlertMessage>}
                    <button className={styles.Close} onClick={this.props.handleClose} disabled={InProgress}>
                        <img src={CloseIcon} alt='' />
                    </button>
                    <DateInput
                        className={`${styles.Input} ${styles.StartDate}`}
                        name="StartDate"
                        label="Start Date:"
                        value={StartDate}
                        onChange={this.handleChange}
                        error={ERROR && ERROR.StartDate}
                    />
                    <TimeInput
                        className={`${styles.Input} ${styles.StartTime}`}
                        name="StartTime"
                        label="Start Time:"
                        value={StartTime}
                        onChange={this.handleChange}
                        error={ERROR && ERROR.StartTime}
                    />
                    <TimeInput
                        className={`${styles.Input} ${styles.EndTime}`}
                        name="EndTime"
                        label="End Time:"
                        value={EndTime}
                        onChange={this.handleChange}
                        error={ERROR && ERROR.EndTime}
                    />
                    <TextInput
                        className={`${styles.Input} ${styles.Location}`}
                        name="Location"
                        label="Location:"
                        value={Location}
                        onChange={this.handleChange}
                        error={ERROR && ERROR.Location}
                    />
                    <TextInput
                        className={`${styles.Input} ${styles.CourseName}`}
                        name="CourseName"
                        label="Course Name:"
                        value={CourseName}
                        onChange={this.handleChange}
                        error={ERROR && ERROR.CourseName}
                    />
                    <Button
                        className={`${styles.Create}`}
                        type="Update"
                        progress={InProgress}
                        onClick={this.handleSubmit}
                        disabled={InProgress}
                    >{this.props.Event ? 'Update' : 'Create'}
                    </Button>
                </div>
            </div>
        )
    }
}
