import React from "react";
import styles from "./Header.module.css";
import Logo from '../../icons/Whitecharacter-stacked-white.svg'
import { Auth } from 'aws-amplify'

const Header = (prop) => {
    const root = document.getElementById("root")
    if (prop.authState) {
        root.className = styles.signedOut
    } else {
        root.className = styles.signedIn
    }
    return (
        <div className={styles.Header} >
            <img className={styles.Logo} src={Logo} alt='' />
            {!prop.authState && <button className={styles.LogOut} onClick={() => Auth.signOut()}><div className={styles.Avatar}>SS</div>Logout </button>}
        </div >
    );
}

export default Header