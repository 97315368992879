import React from "react"
import styles from "./Table.module.css"
import TableRow from "../TableRow/TableRow"
import TableHeader from "../TableHeader/TableHeader"
import Button from '../Button/Button'
import DeleteIcon from '../../icons/Delete.svg'
import UpdateIcon from '../../icons/Update.svg'
import Loading from '../Loading/Loading'

export default class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Items: {},
            Update: {},
            Delete: {},
            InProgressUpdate: false,
            InProgressDelete: false,
            resetInit: true,
            isOpen: false,
            MakeActive: null
        }
    }

    // this.state.Items is empty on the first render
    // until the parent resolves the api request.
    // once the parent passes the initial data, we need to store it in state
    componentDidUpdate() {
        if (this.props.isLoading) this.setState({ Items: { ...this.props.Data } })
    }

    handleUpdateOrDelete = (item, que, action) => {
        if (que) {
            if (action !== "Delete") {
                this.setState({ [action]: { ...this.state[action], [item.Id]: item }, Items: { ...this.state.Items, [item.Id]: item }, resetInit: false }, () => {
                    this.props.hasChanges((Object.values(this.state.Update).length > 0))
                })
                return
            }

            this.setState({ [action]: { ...this.state[action], [item.Id]: item }, resetInit: false }, () => {
                this.props.hasChanges((Object.values(this.state.Update).length > 0))
            })
            return

        }
        let UpdateOrDelete = this.state[action]
        delete UpdateOrDelete[item.Id]
        this.setState({ [action]: UpdateOrDelete, Items: { ...this.state.Items, [item.Id]: item }, resetInit: false }, () => {
            this.props.hasChanges((Object.values(this.state.Update).length > 0))
        })
    }

    onCreate = (item, CallBack) => {
        this.props.Create(item, (status, newItem) => {
            if (status) {
                this.setState({ Items: { ...this.state.Items, [newItem.Id]: newItem }, isOpen: false })
                this.props.hasChanges(false)
                return
            }
            CallBack(false)
        })
    }

    onUpdate = () => {
        const { errors, Items } = this.props.Validate({ ...this.state.Update }, { ...this.state.Items })
        if (errors) return this.setState({ Items: { ...this.state.Items, ...Items } })
        this.setState({ InProgressUpdate: true }, () => {
            this.props.Update(this.state.Update, (status) => {
                if (status) {
                    this.setState({ Update: {}, InProgressUpdate: false, resetInit: true })
                    this.props.hasChanges(false)
                    return
                }
                this.setState({ InProgressUpdate: false })
            })
        })
    }

    onDelete = () => {
        this.setState({ InProgressDelete: true }, () => {
            this.props.Delete(this.state.Delete, (status, deletedItems) => {
                if (status) {
                    let Items = this.state.Items
                    Object.keys(deletedItems).forEach((Id) => delete Items[Id])
                    this.setState({ Items, Delete: {}, InProgressDelete: false })
                    this.props.hasChanges(false)
                    return
                }
                this.setState({ InProgressDelete: false })
            })
        })
    }

    onMakeActive = (item, CallBack) => {
        this.props.Update({ [item.Id]: item }, (status) => {
            if (status) {
                let Items = this.state.Items
                delete Items[item.Id]
                this.setState({ Items, isOpen: false })
                return
            }
            CallBack(false)
        })
    }

    render() {
        const { MakeActive, Title, Delete, Update, AddItemForm, isLoading } = this.props
        const { Items, MakeActiveId, resetInit, InProgressUpdate, InProgressDelete, isOpen } = this.state
        return (
            <div className={styles.Table}>
                <div className={styles.Header}>
                    <p className={styles.TableTitle}>{Title}</p>
                    {Delete && <Button progress={InProgressDelete} className={styles.Delete} type="Delete" SVG={DeleteIcon} onClick={this.onDelete} disabled={Object.values(this.state.Delete).length === 0 || InProgressDelete} />}
                    {(Update && !MakeActive) && <Button progress={InProgressUpdate} className={styles.Update} type="Update" SVG={UpdateIcon} onClick={this.onUpdate} disabled={Object.values(this.state.Update).length === 0 || InProgressUpdate} >Save</Button>}
                </div>
                {Object.keys(Items).length > 0 ? <React.Fragment>
                    <TableHeader item={Object.values(Items)[0]} MakeActive={MakeActive} />
                    <div className={styles.TableBody}>
                        {Object.keys(Items).map((Id) =>
                            <TableRow
                                key={Id}
                                data={Items[Id]}
                                resetInit={resetInit}
                                MakeActive={MakeActive && ((MakeActiveId) => this.setState({ MakeActiveId, isOpen: true }))}
                                handleDelete={this.handleUpdateOrDelete}
                                handleUpdate={this.handleUpdateOrDelete}
                            />
                        )}
                    </div>
                </React.Fragment> : isLoading ? <Loading className={styles.Empty} /> : <p className={styles.Empty}>No {Title}</p>}
                {(InProgressUpdate || InProgressDelete) && <Loading className={styles.Loading} />}
                {AddItemForm && !isLoading && <button className={styles.Add} onClick={() => this.setState({ isOpen: true })}>+</button>}
                {(AddItemForm && isOpen) && <AddItemForm onSubmit={this.onCreate} handleClose={() => this.setState({ isOpen: false })} />}
                {(MakeActive && isOpen) && <MakeActive onSubmit={this.onMakeActive} handleClose={() => this.setState({ isOpen: false })} Event={Items[MakeActiveId]} />}
            </div >
        )
    }
}
