import React from "react";
import styles from "./AlertMessage.module.css";
import CloseIcon from '../../icons/CloseWhite.svg'
import ErrorIcon from '../../icons/Error.svg'

const AlertMessage = (prop) => (
    <div className={styles.Wrapper}>
        <div className={styles.AlertMessage}>
            <img className={styles.Icon} src={ErrorIcon} alt='' />
            {prop.children}
            <img className={styles.Close} src={CloseIcon} alt='' onClick={prop.handleClose} />
        </div>
    </div>
)

export default AlertMessage