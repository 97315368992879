import React from "react";
import styles from "./PastEvents.module.css"
import Table from "../Table/Table";
import CreateOrUpdateEventForm from '../CreateOrUpdateEventForm/CreateOrUpdateEventForm'
import DialogBox from "../DialogBox/DialogBox"
import { API } from 'aws-amplify'

export default class PastEvents extends React.Component {
    constructor(props) {
        super(props)
        this.state = { events: {}, isLoading: true, title: '', message: '' }
    }

    componentDidMount() {
        API.get('contentManagementAPI', '/events?active=false')
            .then((data) => {
                this.setState({ events: data }, () => {
                    this.setState({ isLoading: false })
                })
            }).catch((err) => {
                this.setState({ isLoading: false, title: 'Oops!!', message: 'Sorry an error has occurred. Please reload the page again.' })
            })
    }

    handleUpdateEvents = (updateEvents, CallBack) => {
        API.put('contentManagementAPI', '/events', { body: Object.values(updateEvents) })
            .then(() => CallBack(true)).catch((err) => {
                this.setState({ title: 'Oops!!', message: 'An error has occurred. Please try again.' })
                CallBack(false, err)
            })
    }

    handleDeleteEvents = (deleteEvents, CallBack) => {
        API.del('contentManagementAPI', '/events', { body: Object.values(deleteEvents) })
            .then(() => CallBack(true, deleteEvents)).catch((err) => {
                this.setState({ title: 'Oops!!', message: 'An error has occurred. Please try again.' })
                CallBack(false, err)
            })
    }

    render() {
        const { events, isLoading, title, message } = this.state
        return (
            <div className={styles.PastEvents}>
                {message && <DialogBox type='Error' Ok={() => this.setState({ title: '', message: '' })} title={title} message={message} />}
                <Table
                    Title="Past Events"
                    isLoading={isLoading}
                    hasChanges={this.props.hasChanges}
                    Update={this.handleUpdateEvents}
                    Delete={this.handleDeleteEvents}
                    MakeActive={CreateOrUpdateEventForm}
                    Data={Object.keys(events).length > 0 && events}
                />
            </div>
        )
    }
}
