import React, { useState } from "react";
import styles from "./TableHeader.module.css";
import DateInput from "../DateInput/DateInput.module.css";
import TimeInput from "../TimeInput/TimeInput.module.css";
import TextInput from "../TextInput/TextInput.module.css";
import SelectInput from "../SelectInput/SelectInput.module.css";

const TableHeader = React.memo((props) => {
  const [item] = useState(props.item)
  const { StartDate, EndDate, StartTime, EndTime, Location, CourseName, PromotionalMessage, MakeActive } = item
  return <div className={styles.TableHeader} >
    <span className={styles.Changed} />
    <label className={styles.CheckBox}> <input type="checkbox" disabled /> <span /> </label>
    {StartDate && <div key={"StartDate"} className={DateInput.DateInput}>Start Date</div>}
    {EndDate && <div key={"EndDate"} className={DateInput.DateInput}>End Date</div>}
    {StartTime && <div key={"StartTime"} className={TimeInput.TimeInput}>Start Time</div>}
    {EndTime && <div key={"EndTime"} className={TimeInput.TimeInput}>End Time</div>}
    {Location && <div key={"Location"} className={TextInput.TextInput}>Location</div>}
    {CourseName && <div key={"CourseName"} className={TextInput.TextInput}>Course Name</div>}
    {PromotionalMessage && <div key={"PromotionalMessage"} className={TextInput.TextInput}>Promotional Message</div>}
    {PromotionalMessage && <div key={"Active"} className={SelectInput.SelectInput}>Active</div>}
    {MakeActive && <div className={styles.MakeActive} />}
  </div>
})
export default TableHeader