import React from "react";
import styles from "./SelectInput.module.css"
import InputStyle from '../../styles/Input.module.css'
import ToolTip from '../Tooltip/ToolTip'

const SelectInput = ({ label, value, onChange, onBlur, name, disabled, error, className }) => (
  <ToolTip className={`${styles.SelectInput} ${className ? className : ''}`} error={error}>
    {label && <div className={styles.Label}>{label}</div>}
    <select
      className={`${styles.SelectInput} ${InputStyle.Input} ${error ? InputStyle.Error : ''}`}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    >
      <option value={true}>TRUE</option>
      <option value={false}>FALSE</option>
    </select>
  </ToolTip>
)

export default SelectInput