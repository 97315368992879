import React from "react";
import styles from "./TableRow.module.css";
import DateInput from "../DateInput/DateInput";
import TimeInput from "../TimeInput/TimeInput";
import TextInput from "../TextInput/TextInput";
import SelectInput from "../SelectInput/SelectInput";

export default class TableRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = { initData: { ...this.props.data }, Flash: false, error: {} }
  }

  componentWillUnmount() { clearTimeout(this.timeOut) }

  componentDidUpdate() {
    if (this.props.data.ERROR) { this.setState({ error: { ...this.props.data.ERROR } }, () => { delete this.props.data.ERROR }) }
    if (this.props.resetInit && JSON.stringify(this.props.data) !== JSON.stringify(this.state.initData)) {
      this.setState({ initData: { ...this.props.data }, flash: true, update: false })
      this.timeOut = setTimeout(() => this.setState({ flash: false }), 1000)
    }
  }

  handleChange = ({ target }) => {
    const { initData, error } = this.state
    const { data } = this.props
    let Data = { ...data, [target.name]: target.value }
    if (target.name === "Active") Data = { ...data, [target.name]: (target.value === 'true') }
    if (error[target.name]) {
      let _error = error
      delete _error[target.name]
      this.setState({ error: _error })
    }
    if (this.props.handleUpdate) {
      this.props.handleUpdate(Data, (JSON.stringify(Data) !== JSON.stringify(initData)), "Update")
    }
  }

  render() {
    const { initData, flash, error } = this.state
    const Items = Object.keys(this.props.data)
    const { handleDelete, MakeActive, data } = this.props
    const Fields = { StartDate: DateInput, EndDate: DateInput, StartTime: TimeInput, EndTime: TimeInput, Location: TextInput, CourseName: TextInput, PromotionalMessage: TextInput, Active: SelectInput }
    return <div className={`${styles.TableRow} ${flash ? styles.Flash : ''}`}>
      <div className={(JSON.stringify(data) !== JSON.stringify(initData)) ? styles.Changed : styles.Hide} />
      <label className={styles.CheckBox}> <input type="checkbox" name="checked" onChange={({ target }) => handleDelete(data, target.checked, "Delete")} /> <span /> </label>
      {Items.map((item) => {
        if (Fields[item]) {
          const Field = Fields[item]
          return <Field
            key={item}
            name={item}
            value={data[item]}
            onChange={this.handleChange}
            disabled={MakeActive && true}
            error={error && error[item]}
          />
        }
        return null
      })}
      {MakeActive && <button className={styles.MakeActive} onClick={() => MakeActive(this.props.data.Id)}>Make Active</button>}
    </div>
  }
}
